<template>
  <div>
    <qiniu-upload
      ref="qiniuImageUpload"
      :pickfiles="'pickfiles-files-' + uploadUUID"
      :container="'container-files-' + uploadUUID"
      :documentDirId="documentDirId"
      :notShowDefaultList="true"
      :filters="filters"
      :autoStart="autoStart"
      :multiSelection="multiSelection"
      :maxSize="maxSize"
      :updaloadTypes="updaloadTypes"
      @onFilesAdded="onFilesAdded"
      @onOneUploadComplete="onOneUploadComplete"
      @onUploadComplete="onUploadComplete"
      @onBeforeUpload="onBeforeUpload"
      @onError="onError"
    >
      <div slot="uploader">
        <div :id="'container-files-' + uploadUUID">
          <div v-if="type === 'edit'">
            <a-button
              type="dashed"
              :loading="uploadStatus"
              class="m-r-12 cursor-pointer"
              icon="cloud-upload"
              :id="'pickfiles-files-' + uploadUUID"
              >上传图片
            </a-button>
            {{ tips }}
          </div>
        </div>
      </div>
    </qiniu-upload>
    <div class="image-page">
      <div class="file-item">
        <image-view-item
          v-for="(image, index) in images"
          :key="'image-' + image.id"
          :image="image"
          :type="type"
          @removeImg="removeImgHandle"
          :index="index"
          :images="images"
        ></image-view-item>
      </div>
    </div>
  </div>
</template>
<script>
import QiniuUpload from './QiniuUpload'
import uploadCommon from '../../components/LocalUpload/mixins/upload-common'
import ImageViewItem from './image-view-item.vue'
import { fileIconFormat, humanReadableUnits } from '../../utils/utils'
import dragAndDrop from '../../directives/drag-and-drop'
import '../../plugins/fileDownload/download.min'

export default {
  name: 'QiniuImageUpload',
  props: {
    type: {
      type: String,
      default: 'edit',
    },
    documentDirId: {
      type: Number,
      default: -1,
    },
    tips: {
      type: String,
      default: '支持文件拓展名:.png .jpeg .jpg',
    },
    maxSize: {
      type: String,
      default: '10mb',
    },
    updaloadTypes: {
      type: Array,
      default: function () {
        return ['jpg', 'png'];
      },
    },
    multiSelection: {
      type: Boolean,
      default: false,
    },
    autoStart: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Object,
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  mixins: [uploadCommon],
  data() {
    return {
      format: fileIconFormat,
      humanReadableUnits: humanReadableUnits,
      images: [],
      uploadFiles: [],
    }
  },
  created() {
    this.forEachiItems()
  },
  watch: {
    items() {
      this.images = []
      this.items.forEach((data) => {
        this.images.push({
          src: data.bucketUrl + data.fileUrl,
          msrc: data.bucketUrl + data.fileUrl + '?imageView2/2/w/300/h/300',
          w: data.w,
          h: data.h,
          title: data.name,
          id: data.id,
        })
      })
    },
  },
  filters: {
    subString(value) {
      if (!value) return ''
      if (value.length > 20) {
        return value.substring(0, 20) + '...'
      }
      return value
    },
  },
  methods: {
    forEachiItems() {
      this.images = []
      this.items.forEach((data) => {
        this.images.push({
          src: data.bucketUrl + data.fileUrl,
          msrc: data.bucketUrl + data.fileUrl + '?imageView2/2/w/300/h/300',
          w: data.w,
          h: data.h,
          title: data.name,
          id: data.id,
        })
      })
    },
    /**
     * 上传增加回调方法
     * @param files 本次上传的所有文件
     * @param allFiles 所有上传的文件
     */
    onFilesAdded(files, allFiles) {
      // allFiles.forEach((file) => {
      //   this.uploadFiles.push(file)
      // })
    },
    onOneUploadComplete(data) {
      this.images.push({
        src: data.bucketUrl + data.fileUrl,
        msrc: data.bucketUrl + data.fileUrl + '?imageView2/2/w/300/h/300',
        w: data.w,
        h: data.h,
        title: data.name,
        id: data.id,
      })
    },
    // 移除文件
    removeFile(file) {
      this.$refs.qiniuImageUpload.removeFile(file)
      this.uploadFiles.splice(this.uploadFiles.indexOf(file), 1)
      let index_f = this.images.findIndex((i) => i.title === file.name)
      this.images.splice(index_f, 1)
    },
    onUploadComplete() {
      this.uploadStatus = false
      this.$emit('getImageData', this.images)
      // 所有文件上传完成 多文件上传需要监听
      this.$emit('onUploadComplete')
    },
    // 移除文件展示
    removeImgHandle(index, item) {
      this.images.splice(index, 1)
      let index_f = this.uploadFiles.findIndex((i) => i.name === item.title)
      this.$refs.qiniuImageUpload.removeFile(this.uploadFiles[index_f])
      this.$emit('getImageData', this.images)
    },
  },
  components: {
    QiniuUpload,
    ImageViewItem,
  },
  directives: { dragAndDrop },
}
</script>
<style lang="less" type="text/less">
.image-page {
  padding: 15px 0 0 0;
  .file-item {
    display: inline-block;
    vertical-align: top;
    img {
      border-radius: 6px;
      margin: 3px;
    }
    .inline {
      position: relative;
      .delete-icon {
        position: absolute;
        right: 3px;
        top: -30px;
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        background: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        color: #fff;
        text-align: center;
      }
    }
  }
}
</style>
