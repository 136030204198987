<template>
  <div class="m-r-10 m-b-10 inline panel-content-default cursor-pointer"
       style="height:90px;display: inline">
    <img width="100px" height="90px"
         :src="image.src + '?' + imageView2" @click.stop="$preview.open(index, images,config)" class="preview-img">
    <a-icon v-if="type==='edit'" type="delete" class="delete-icon" @click="removeImg(index, image)"/>
  </div>
</template>
<script>

  import { previewConfig } from '../../utils/preview'

  export default {
    props: {
      image: {},
      images: {
        type: Array,
        default: () => {
          return []
        }
      },
      type: {
        type: String,
        default: 'edit'
      },
      index: {
        type: Number
      }
    },
    data () {
      return {
        config: previewConfig,
        imageView2: 'imageView2/4/w/300/h/260',
        defaultW: 320,
        scale: 250 / 320
      }
    },
    computed: {
      imageWidth () {
        // 图片区域展示大小
        const sumWidth = this.$store.getters.bodyWidth - 918 - 25
        let count
        if (sumWidth % this.defaultW > this.defaultW / 2) {
          count = parseInt(sumWidth / this.defaultW) + 1
        } else {
          count = parseInt(sumWidth / this.defaultW)
        }
        // 计算每个 image 宽 每个 image 间隔 15px
        return parseInt((sumWidth - count * 15) / count)
      }
    },
    methods: {
      removeImg (index, item) {
        this.$emit('removeImg', index, item)
      }
    }
  }
</script>
