<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">确认</h1>
    </div>
   <!-- <div slot="extra">
      <a-button @click="handleDamageReportAdd" icon="plus" type="primary" style="margin-left: 10px">上报登记</a-button>
      <a-button @click="exportWord()" icon="export" type="primary" style="margin-left: 10px">导出</a-button>
    </div>-->
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="集污点编号">
              <a-input v-model="searchParams.no" @change="search" placeholder="集污点编号"/>
            </a-form-item>
            <a-form-item label="处理状态">
              <a-select
                allowClear
                showSearch
                v-model="searchParams.handleStatus"
                placeholder="请选择处理状态"
                @change="search">
                <a-select-option
                  v-for="(item, index) in processStatusList"
                  :key="index"
                  :value="item.value"
                  :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="任务状态">
              <a-select
                allowClear
                showSearch
                v-model="searchParams.bindStatus"
                placeholder="请选择任务状态"
                @change="search">
                <a-select-option
                  v-for="(item, index) in bindStatusList"
                  :key="index"
                  :value="item.value"
                  :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="人员名称">
              <a-input v-model="searchParams.fromName" @change="search" placeholder="人员名称"/>
            </a-form-item>
            <a-form-item label="来电电话">
              <a-input v-model="searchParams.fromPhone" @change="search" placeholder="来电电话"/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
              <a :style="{ marginLeft: '5px', fontSize: '12px' }" @click="toggle">
                {{ expand ? '收起' : '展开' }}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>
            </a-form-item>
            <a-row v-if="expand" style="margin-top: 10px;margin-bottom: 10px;">
              <a-form-item label="上报原因">
                <a-input v-model="searchParams.reason" @change="search" placeholder="上报原因"/>
              </a-form-item>
              <a-form-item label="来电地址">
                <a-input v-model="searchParams.fromAddress" @change="search" placeholder="来电地址"/>
              </a-form-item>
              <a-form-item label="值班人员">
                <a-input v-model="searchParams.officer" @change="search" placeholder="值班人员"/>
              </a-form-item>
              <a-form-item label="上报时间">
                <a-range-picker @change="dateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>
              <a-form-item label="上报类型">
                <a-radio-group
                  v-model="searchParams.type" @change="search">
                  <a-radio-button value="TEL">
                    电话
                  </a-radio-button>
                  <a-radio-button value="WECHAT">
                    微信
                  </a-radio-button>
                  <a-radio-button value="APP">
                    APP
                  </a-radio-button>
                  <a-radio-button value="OTHER">
                    其他
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <!--<a-form-item label="处理完成时间">
                <a-range-picker @change="handleCompleteDateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>
              <a-form-item label="回访时间">
                <a-range-picker @change="feedbackDateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>
              <a-form-item label="确认时间">
                <a-range-picker @change="confirmDateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>-->
            </a-row>
            <a-row :gutter="[16,16]">
              <a-spin :spinning="spinning">
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card>
                    <a-statistic
                      title="上报总数"
                      :value="pagination.total"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card>
                    <a-statistic
                      title="待回访"
                      :value="damageStatistics.PROCESSED"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card>
                    <a-statistic
                      title="待确认"
                      :value="damageStatistics.FEEDBACK_COMPLETE"
                      :valueStyle="valueStyle"
                      style="text-align: center; color: #1890ff;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card>
                    <a-statistic
                      title="已完成"
                      :value="damageStatistics.CONFIRM_COMPLETE"
                      :valueStyle="valueStyle"
                      style="text-align: center; color: #1890ff;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
              </a-spin>
            </a-row>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :customRow="handleView"
        :scroll="{ x: 1400 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="type" slot-scope="text, record">
          <a-tag v-if="record.type==='TEL'" color="#00BFFF">
            <a-icon type="phone"/>&nbsp;&nbsp;电话
          </a-tag>
          <a-tag v-if="record.type==='WECHAT'" color="#00CD66">
            <a-icon type="wechat"/>&nbsp;&nbsp;微信
          </a-tag>
          <a-tag v-if="record.type==='APP'" color="#00CED1">
            <a-icon type="calendar"/>&nbsp;&nbsp;APP
          </a-tag>
          <a-tag v-if="record.type==='OTHER'" color="#00CED1">
            <a-icon type="calendar"/>&nbsp;&nbsp;自主任务
          </a-tag>
        </template>
        <template slot="handleStatus" slot-scope="text, record">
          <a-tag v-if="record.handleStatus==='SCHEDULED'" color="#33CC33">
            已调度
          </a-tag>
          <a-tag v-if="record.handleStatus==='PROCESSING'" color="#6666CC">
            待处理
          </a-tag>
          <a-tag v-if="record.handleStatus==='PROCESSED'" color="#CC6699">
            待回访
          </a-tag>
          <a-tag v-if="record.handleStatus==='FEEDBACK_COMPLETE'" color="#FF9900">
            待确认
          </a-tag>
          <a-tag v-if="record.handleStatus==='CONFIRM_COMPLETE'" color="#99CC33">
            已完成
          </a-tag>
        </template>
        <template slot="bindStatus" slot-scope="text, record">
          <a-tag v-if="record.bindStatus==='NOTOPEN'" color="red">
            未审核
          </a-tag>
          <a-tag v-if="record.bindStatus==='OPTIONAL'" color="blue">
            待接单
          </a-tag>
          <a-tag v-if="record.bindStatus==='CHECKED'" color="green">
            已接单
          </a-tag>
          <a-tag v-if="record.bindStatus==='DOING'" color="pink">
            清运中
          </a-tag>
          <a-tag v-if="record.bindStatus==='COMPLETE'" color="#CC9966">
            已清运
          </a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <a v-if="record.handleStatus==='FEEDBACK_COMPLETE'" @click.stop="handleConfirm(record.id)">确认</a>
          <a v-if="record.handleStatus==='CONFIRM_COMPLETE'" href="javascript:return false;" onclick="return false;"
             style="color: #c5c2bf;" @click.stop>已确认</a>
          <a-divider v-if="record.handleStatus==='CONFIRM_COMPLETE'" type="vertical"/>
          <a v-if="record.handleStatus==='CONFIRM_COMPLETE'" @click.stop="handlePrint(record.id)">打印</a>
          <a-divider type="vertical" v-if="record.handleStatus==='UN_PROCESS'||record.handleStatus==='PROCESSING'"/>
          <a @click.stop="handleDelete(record.id)" v-if="record.handleStatus==='UN_PROCESS'||record.handleStatus==='PROCESSING'">删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      width="1200px"
      title="修改上报内容"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal">
      <register-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></register-form>
    </a-modal>
    <a-modal
      title="报抽登记"
      :visible="addModal"
      width="1200px"
      :maskClosable="true"
      centered
      @ok="handleAddOk"
      @cancel="closeAddModal">
      <register-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></register-form>
    </a-modal>
    <!--調度Modal-->
    <!--<a-modal
      title="调度" width="800px"
      :visible="dispatchModal"
      :maskClosable="true"
      centered
      @ok="dispatchAddOk"
      @cancel="closeDispatchModal">
      <dispatch ref="dispatchRef" type="add" @dispatchSuccess="dispatchSuccess"
                @dispatchError="dispatchError"></dispatch>
    </a-modal>-->
    <!--回訪Modal-->
    <!--<a-modal
      title="回访"
      :visible="feedbackModal"
      :maskClosable="true"
      centered
      @ok="feedbackAddOk"
      @cancel="closeFeedbackModal">
      <feedback ref="feedbackRef" type="add" @feedbackSuccess="feedbackSuccess"
                @feedbackError="feedbackError"></feedback>
    </a-modal>-->
<!--    确认Modal-->
    <a-modal
      title="确认"
      :visible="confirmModal"
      :maskClosable="true"
      centered
      @ok="confirmAddOk"
      @cancel="closeConfirmModal">
      <confirm ref="confirmRef" type="add" @confirmSuccess="confirmSuccess"
               @confirmError="confirmError"></confirm>
    </a-modal>
    <a-drawer
      title="上报详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="750px"
      :visible="detailDrawer"
      :mask="false">
      <damage-report-detail ref="viewRef"></damage-report-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { reportColumns, reportTypeList, processStatusList, bindStatusList } from './common/common'
  import PageLayout from '@/components/page/PageLayout'
  import RegisterForm from './Form'
  // import Dispatch from './Dispatch'
  // import Feedback from './Feedback'
  import Confirm from './Confirm'
  import DamageReportDetail from './Detail'
  import entityCRUD from '../../../common/mixins/entityCRUD'
  import moment from 'moment'

  export default {
    name: 'ConfirmList',
    components: { Confirm, RegisterForm, PageLayout, DamageReportDetail },
    mixins: [entityCRUD],
    data() {
      return {
        editLoading: false,
        entityBaseUrl: SERVICE_URLS.csgz.reportRecord,
        detailDrawer: false,
        addModal: false,
        dispatchModal: false,
        feedbackModal: false,
        confirmModal: false,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          fromName: '',
          fromPhone: '',
          fromAddress: '',
          startFromTime: '',
          endFromTime: '',
          reason: '',
          type: '',
          gatherSpotType: '',
          officer: '',
          driverId: '',
          dispatcherId: '',
          no: '',
          startDispatchTime: '',
          endDispatchTime: '',
          startHandleCompleteTime: '',
          endHandleCompleteTime: '',
          startFeedbackTime: '',
          endFeedbackTime: '',
          startConfirmTime: '',
          endConfirmTime: '',
          confirmUserId: '',
          handleStatus: 'FEEDBACK_COMPLETE',
          bindStatus: ''
        },
        initColumns: reportColumns(),
        tableData: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
        },
        expand: false,
        dateSearch: null, //日期绑定数据
        processStatusList: processStatusList(),
        bindStatusList: bindStatusList(),
        reportTypeList: reportTypeList(),
        damageStatistics: {},
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        damageReportId: '' //当前点击记录ID
      }
    },
    mounted() {
      this.search()
    },
    created() {
      this.getStatisticsData()
      this.search()
    },
    computed: {
      currentUser() {
        return this.$store.getters.currentUser
      }
    },
    methods: {
      moment,
      selectStatus() {
        this.search()
      },
      getStatisticsData() {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.statistics,
          noTips: true,
          success: (data) => {
            this.damageStatistics = data.body
            this.spinning = false
          }
        })
        this.spinning = false
      },
      deleteSucceed() {
        this.getStatisticsData()
        this.search()
      },
      dateChange(date, dateString) {
        this.searchParams.startFromTime = dateString[0]
        this.searchParams.endFromTime = dateString[1]
        this.search()
      },
      dispatchDateChange(date, dateString) {
        this.searchParams.startDispatchTime = dateString[0]
        this.searchParams.endDispatchTime = dateString[1]
        this.search()
      },
      handleCompleteDateChange(date, dateString) {
        this.searchParams.startHandleCompleteTime = dateString[0]
        this.searchParams.endHandleCompleteTime = dateString[1]
        this.search()
      },
      feedbackDateChange(date, dateString) {
        this.searchParams.startFeedbackTime = dateString[0]
        this.searchParams.endFeedbackTime = dateString[1]
        this.search()
      },
      confirmDateChange(date, dateString) {
        this.searchParams.startConfirmTime = dateString[0]
        this.searchParams.endConfirmTime = dateString[1]
        this.search()
      },
      detailClose() {
        this.detailDrawer = false
      },
      handleView(record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      handleDamageReportAdd() {
        this.addModal = true
        //加载集污点信息
        /*this.$nextTick(() => {
          this.$refs['addRef'].loadGatherSpot()
        })*/
      },
      //上报
      handleAddOk() {
        this.$refs['addRef'].submitAdd()
      },
      closeAddModal() {
        this.addModal = false
      },
      addSuccess() {
        this.addModal = false
        this.$message.success('登记成功！')
        this.search()
        this.getStatisticsData()
      },
      addError() {
        this.$message.warning('登记失败！')
        this.addModal = false
      },
      //调度
      handleDispatch(id) {
        this.dispatchModal = true
        this.damageReportId = id
      },
      dispatchAddOk() {
        this.$refs['dispatchRef'].dispatch(this.damageReportId)
      },
      closeDispatchModal() {
        this.dispatchModal = false
      },
      dispatchSuccess() {
        this.dispatchModal = false
        this.$message.success('调度成功！')
        this.search()
        this.getStatisticsData()
      },
      dispatchError() {
        this.$message.warning('调度失败！')
      },
      //回访
      handleFeedback(id) {
        this.feedbackModal = true
        this.damageReportId = id
      },
      feedbackAddOk() {
        this.$refs['feedbackRef'].feedback(this.damageReportId)
      },
      closeFeedbackModal() {
        this.feedbackModal = false
      },
      feedbackSuccess() {
        this.feedbackModal = false
        this.$message.success('回访成功！')
        this.search()
        this.getStatisticsData()
      },
      feedbackError() {
        this.$message.warning('回访失败！')
        this.feedbackModal = false
      },
      //确认
      handleConfirm(id) {
        this.confirmModal = true
        this.damageReportId = id
        this.$nextTick(() => {
          this.$refs['confirmRef'].confirmPerson()
        })
      },
      confirmAddOk() {
        this.$refs['confirmRef'].confirm(this.damageReportId)
        this.confirmModal = false
      },
      closeConfirmModal() {
        this.confirmModal = false
      },
      confirmSuccess() {
        this.confirmModal = false
        this.$message.success('确认成功！')
        this.search()
        this.getStatisticsData()
      },
      confirmError() {
        this.$message.warning('确认失败！')
        this.confirmModal = false
      },
      toggle() {
        this.expand = !this.expand
      },
      exportWord() {
        const fileName = '登记上报-' + moment().format('YYYYMMDDHHmmss')
        this.handExportWord(fileName)
      }
    }
  }
</script>
