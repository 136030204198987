<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="确认人" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['confirmPerson',{ rules: [{ required: true, message: '确认人不能为空' }] }]" placeholder="请输入确认人"></a-input>
      </a-form-item>
      <a-form-item label="确认结果" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4" v-decorator="['confirmResult',
        { rules: [{ required: true, message: '回访结果不能为空' }] }]" placeholder="请输入确认结果"></a-input>
      </a-form-item>
    </a-form>
  </a-spin>
</template>
<script>
  import { damageReportFormVO } from './common/common'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'confirmForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        form: this.$form.createForm(this),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        ifSub: false,
        formItem: damageReportFormVO()
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
    },
    methods: {
      confirmPerson () {
        this.form.setFieldsValue({
          'confirmPerson': this.currentUser.name
        })
      },
      setVOFields (values) {
        this.formItem.reportRecord.confirmResult = values.confirmResult
        this.formItem.reportRecord.confirmPerson = values.confirmPerson
      },
      confirm (id) {
        this.formItem.reportRecord.handleStatus = 'CONFIRM_COMPLETE'
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.csgz.reportRecord.updateHandleStatus,
                params: { id: id },
                data: this.formItem,
                noTips: true,
                success: (data) => {
                  //清空表单数据
                  this.formItem = damageReportFormVO()
                  this.form.resetFields()
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('confirmSuccess')
                },
                error: () => {
                  this.$emit('confirmError')
                }
              })
            }
          })
      },
      handleTypeChange (e) {
        this.handleType = e.target.value
      },
    }
  }
</script>

<style scoped></style>