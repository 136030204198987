/**
 * Created by yangyang on 2017/6/26.
 */
export const previewConfig = {
  barsSize: { top: 44, bottom: 'auto' },

  // Adds class pswp__ui--idle to pswp__ui element when mouse isn't moving for 4000ms
  timeToIdle: 4000,

  // Same as above, but this timer applies when mouse leaves the window
  timeToIdleOutside: 1000,

  // Delay until loading indicator is displayed
  loadingIndicatorDelay: 1000,
  showHideOpacity: true,
  bgOpacity: 0.5,
  closeEl: true,
  captionEl: true,
  fullscreenEl: false,
  zoomEl: true,
  shareEl: false,
  counterEl: true,
  arrowEl: true,
  preloaderEl: true,
  closeOnScroll: false,
  preload: [1, 3],
  closeOnVerticalDrag: true,
  pinchToClose: false,
  mouseUsed: true,
  history: false,
  // Tap on sliding area should close gallery
  tapToClose: true,
  // Tap should toggle visibility of controls
  tapToToggleControls: true,

  // Mouse click on image should close the gallery,
  // only when image is smaller than size of the viewport
  clickToCloseNonZoomable: false
}
